import { Map, MapOptions } from "maplibre-gl";
import { createSignal, JSX, onCleanup, onMount, ParentProps, Show, splitProps } from "solid-js";
import SolidMapProvider from "./SolidMapProvider";

export default function SolidMap(
  props: ParentProps<
    JSX.HTMLAttributes<HTMLDivElement> & { mapOptions?: Omit<MapOptions, "container">; onInit?: (map: Map) => void }
  >
) {
  const [localProps, divProps] = splitProps(props, ["children", "mapOptions", "onInit"]);

  let map: Map;
  let mapElementRef!: HTMLDivElement;

  const [loadedMap, setLoadedMap] = createSignal<Map>();

  onMount(() => {
    map = new Map({
      container: mapElementRef,
      ...props.mapOptions,
    });

    map.once("load", () => setLoadedMap(map));
    localProps.onInit?.(map);
  });

  onCleanup(() => {
    map.remove();
  });

  return (
    <>
      <Show when={loadedMap()}>{(map) => <SolidMapProvider map={map()}>{localProps.children}</SolidMapProvider>}</Show>
      <div ref={mapElementRef} {...divProps}></div>
    </>
  );
}
