import { Marker } from "maplibre-gl";
import { ParentComponent, Show, createEffect, onCleanup } from "solid-js";
import { SolidMapMarker, useSolidMap } from "solid-map";
import { liveLocationsCache, vehiclesCache } from "supabase-client";
import { driverMarkerSizeMultiplier } from "../storage";
import classes from "./MapDriverMarker.module.scss";
import { MapDriverMarkerPopup } from "./MapDriverMarkerPopup";
type Props = {
  driver_id: string;
  follow?: boolean;
};

function DriverMarkerIcon(props: {
  identifier?: string;
  heading?: number | null;
  speed: number;
  driverstatusClassName?: string | null;
}) {
  return (
    <>
      <div class={`${classes.icon} ${props.driverstatusClassName}`}>{props.identifier}</div>
      <Show when={typeof props.heading === "number" && props.heading !== -1 && props.speed * 3.6 >= 10}>
        <div
          class={classes.direction}
          style={{
            transform: `rotate(${props.heading}deg)`,
          }}
        >
          <div class={`${classes.arrow} ${props.driverstatusClassName}`} />
        </div>
      </Show>
    </>
  );
}

export const driverMarkerInstances = new Map<string, Marker>();

export const MapDriverMarker: ParentComponent<Props> = (props) => {
  const liveLocation = () => liveLocationsCache.get(props.driver_id)!;
  const vehicle = () => vehiclesCache.get(liveLocation().current_vehicle_id!);
  const driverstatus = () => liveLocation().status;
  const driverstatusClass = () => driverstatus() && classes[driverstatus()!];
  const identifier = () => vehicle()?.identifier;
  const heading = () => liveLocation().heading;
  const speed = () => liveLocation().speed ?? 0;

  const map = useSolidMap();

  createEffect(() => {
    const { latitude, longitude } = liveLocation();
    if (latitude && longitude) {
      if (props.follow) {
        const zoomlevel = calculateZoomLevel(speed() * 30, latitude, map.getContainer().clientWidth);
        map.flyTo({ center: [longitude, latitude], zoom: Math.min(zoomlevel, 18) });
      }
    }
  });

  onCleanup(() => {
    driverMarkerInstances.delete(props.driver_id);
  });

  return (
    <SolidMapMarker
      lngLat={[liveLocation().longitude!, liveLocation().latitude!]}
      onInit={(marker) => {
        driverMarkerInstances.set(props.driver_id, marker);
      }}
      popupOptions={{
        closeButton: true,
        closeOnMove: false,
        closeOnClick: true,
        anchor: "bottom",
        focusAfterOpen: false,
        offset: [0, -13 * driverMarkerSizeMultiplier()],
      }}
      markerOptions={{
        className: classes.marker,
      }}
      popupElement={<MapDriverMarkerPopup driver_id={props.driver_id} />}
    >
      <DriverMarkerIcon
        identifier={identifier()}
        heading={heading()}
        speed={speed()}
        driverstatusClassName={driverstatusClass()}
      />
    </SolidMapMarker>
  );
};

function calculateZoomLevel(widthMeters: number, latitude: number, mapWidth: number): number {
  // Constants
  const earthCircumference = 40075016.686; // Equatorial circumference of the Earth in meters
  const tileSize = 512; // Tile size in pixels

  // Calculate the zoom level
  const zoomLevel = Math.log2(
    (earthCircumference * Math.cos(latitude * (Math.PI / 180))) / ((widthMeters / mapWidth) * tileSize)
  );

  return zoomLevel;
}
