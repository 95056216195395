import { captureException } from "@sentry/browser";
import { Key } from "@solid-primitives/keyed";
import { debounce } from "@solid-primitives/scheduled";
import { makePersisted } from "@solid-primitives/storage";
import { NewMap, NewPlaceSearch, RideForm, ct, currentLocale } from "components";
import { driverMarkerInstances } from "components/src/map/MapDriverMarker";
import {
  PassengerCountButton,
  PassengerCountSelectorOverlay,
  PlaceContainer,
  SimplePriceOptionsOverlay,
} from "components/src/rideForm/rideForm.common";
import EventEmitter from "events";
import { MyDate, MyTime, MyTimestamp } from "helpers";
import mooovexApiClient from "mooovex-api-client";
import * as API from "mooovex-api-schema";
import { Component, For, Show, createEffect, createSignal, createUniqueId, on, onCleanup, onMount } from "solid-js";
import {
  currentRoles,
  liveLocationsCache,
  liveTaxistationsMap,
  myStaff,
  subscribeToLiveLocations,
  subscribeToLiveTaxistations,
  toGeoJSON,
  unsubscribeFromLiveLocations,
  unsubscribeFromLiveTaxistations,
} from "supabase-client";
import { ClientDriverlist } from "../components/ClientDriverlist";
import { Datepicker } from "../components/Datepicker";
import { TaxistationsC } from "../components/Taxistations.c";
import TransportcompanySelectorC from "../components/TransportcompanySelector.c";
import { selectedTransportcompanyId } from "../services/state/transportcompanies.state";

const DriversPage: Component = () => {
  createEffect(
    on([selectedTransportcompanyId, myStaff], async ([id, staff], prev) => {
      if (prev && prev[0] !== id) {
        await unsubscribeFromLiveLocations(true);
      }

      if (id && staff) {
        await subscribeToLiveLocations(id);
      }
    })
  );

  const onlineDrivers = () =>
    Array.from(liveLocationsCache.values()).filter(
      (liveLocation) => liveLocation.status && liveLocation.status !== "outofservice"
    );

  const visibleDrivers = () => onlineDrivers().filter((d) => d.latitude && d.longitude && d.status !== "invisible");

  const centeredDriverIdSignal = createSignal<string>();
  const [centeredDriverId, setCenteredDriverId] = centeredDriverIdSignal;

  const taxistationFeatures = () =>
    Array.from(liveTaxistationsMap.values())
      .filter((station) => !station.disabled)
      .map(
        (station) =>
          ({
            type: "Feature",
            geometry: toGeoJSON(station.location),
            properties: {
              disabled: station.disabled,
              color: station.active ? "#002672" : "gray",
              circleOpacity: station.active ? 0.2 : 0.1,
              textOpacity: station.active ? 1 : 0.6,
              name: station.name,
            },
          }) satisfies API.Shared.GeoJson.Feature
      );

  onMount(async () => {
    await subscribeToLiveTaxistations();
    rideFormEmitter
      .on("placeSearch.error", onPlaceError)
      .on("placeSearch.value", onPlaceValue)
      .on("startDatePicker.value", setStartDate)
      .on("startTimePicker.value", setStartTime)
      .on("clear", onClear)
      .on("placeSearch.openInGoogleMaps", onOpenInGoogleMaps);
  });

  onCleanup(async () => {
    await unsubscribeFromLiveLocations();
    await unsubscribeFromLiveTaxistations();
    rideFormEmitter.removeAllListeners();
  });

  const routeSignal = createSignal<API.route.ResponseBody>();
  const priceSignal = createSignal<number>();
  const discountSignal = createSignal<number>(0);
  const passengerCountSignal = createSignal<number>();
  const startTimeSignal = createSignal<MyTime>();
  const startDateSignal = createSignal<MyDate>();
  const placeContainersSignal = makePersisted(
    createSignal<PlaceContainer[]>([
      { containerId: createUniqueId(), place: undefined },
      { containerId: createUniqueId(), place: undefined },
    ]),
    {
      name: "mooovex:currentRide.placeContainers",
    }
  );
  const routeLoadingSignal = createSignal(false);
  const priceLoadingSignal = createSignal(false);
  const formVisibleSignal = createSignal(true);

  const [route, setRoute] = routeSignal;
  const [price, setPrice] = priceSignal;
  const [passengerCount, setPassengerCount] = passengerCountSignal;
  const [discount, setDiscount] = discountSignal;
  const [startTime, setStartTime] = startTimeSignal;
  const [startDate, setStartDate] = startDateSignal;
  const [placeContainers, setPlaceContainers] = placeContainersSignal;
  const [routeLoading, setRouteLoading] = routeLoadingSignal;
  const [priceLoading, setPriceLoading] = priceLoadingSignal;
  const [formVisible, setFormVisible] = formVisibleSignal;

  const sortedWaypoints = () => placeContainers().flatMap((c) => (c.place ? [c.place] : []));

  const rideFormEmitter = new EventEmitter() as RideForm.RideFormEmitter;

  const debouncedRouteCalculation = debounce(async () => {
    if (sortedWaypoints().length >= 2) {
      setRoute(undefined);

      const date = startDate();
      const time = startTime();
      const timestamp = MyTimestamp.fromMyDateAndMyTime(date ?? MyDate.today(), time ?? MyTime.now());

      try {
        setRouteLoading(true);
        const route = await mooovexApiClient.route.compute(
          sortedWaypoints().map(API.PlaceAdapter.getCoordinates),
          timestamp,
          selectedTransportcompanyId()
        );
        setRoute(route);
      } catch (error) {
        captureException(error);
        alert(ct["ride.route.calculation.error"]());
      } finally {
        setRouteLoading(false);
      }
    } else {
      setRoute(undefined);
    }
  }, 100);

  const debouncedPriceCalculation = debounce(async () => {
    if (route() && passengerCount() !== undefined) {
      setPrice(undefined);
      const date = startDate();
      const time = startTime();
      const timestamp = MyTimestamp.fromMyDateAndMyTime(date ?? MyDate.today(), time ?? MyTime.now());

      try {
        setPriceLoading(true);
        const price = await mooovexApiClient.price.compute({
          routeId: route()!.id,
          passengerCount: passengerCount()!,
          discount: discount(),
          timestamp,
          transportcompanyId: selectedTransportcompanyId(),
        });
        setPrice(price.price);
      } catch (error) {
        captureException(error);
        alert(ct["price.calculation.error"]());
        setPrice(undefined);
      } finally {
        setPriceLoading(false);
      }
    } else {
      setPrice(undefined);
    }
  }, 300);

  // Route Calclculation
  createEffect(on(sortedWaypoints, debouncedRouteCalculation));

  // Price calculation
  createEffect(
    on([route, passengerCount, startDate, startTime, discount, selectedTransportcompanyId], debouncedPriceCalculation)
  );

  function onPlaceError(message: string) {
    alert(message);
  }

  async function onPlaceValue(containerId: string, value?: API.google_place_details.ResponseBody) {
    setPlaceContainers((prevContainers) =>
      prevContainers.map((container) =>
        container.containerId === containerId ? { ...container, place: value } : container
      )
    );
  }

  const encodeURIComponentTag = (strings: TemplateStringsArray, ...values: string[]) =>
    values.reduce((result, value, i) => result + strings[i] + encodeURIComponent(value), "");

  function onOpenInGoogleMaps(
    placeOrCoordinates: API.google_place_details.ResponseBody | API.Shared.GeoJson.Coordinates
  ) {
    let url = "";

    if (Array.isArray(placeOrCoordinates)) {
      url = encodeURIComponentTag`https://www.google.com/maps/search/?api=1&query=${placeOrCoordinates
        .reverse()
        .join(",")}`;
    } else if (API.PlaceAdapter.isGh(placeOrCoordinates)) {
      url = encodeURIComponentTag`https://www.google.com/maps/search/?api=1&query=${placeOrCoordinates.point
        .reverse()
        .join(",")}`;
    } else {
      url = encodeURIComponentTag`https://www.google.com/maps/search/?api=1&query=${placeOrCoordinates.name}&query_place_id=${placeOrCoordinates.google_place_id}`;
    }

    window.open(url, "_system");
  }

  function onClear() {
    setPlaceContainers([
      { containerId: createUniqueId(), place: undefined },
      { containerId: createUniqueId(), place: undefined },
    ]);

    rideFormEmitter.emit("startTimePicker.value", undefined);
    rideFormEmitter.emit("startDatePicker.value", undefined);
    rideFormEmitter.emit("priceOptions.reset");
    setPassengerCount(undefined);
  }

  return (
    <>
      <div class="d-flex h-100 overflow-x-hidden">
        <RideForm.RideFormProvider
          {...{
            passengerCountSignal,
            startDateSignal,
            startTimeSignal,
            routeSignal,
            priceSignal,
            discountSignal,
            emitter: rideFormEmitter,
            centeredDriverIdSignal: createSignal(),
            placeContainersSignal,
            priceLoadingSignal,
            routeLoadingSignal,
            sortedWaypoints,
            formVisibleSignal,
          }}
        >
          <div class="d-flex flex-column flex-grow-1 h-100 overflow-hidden">
            <NewMap.MapComponent class="flex-grow-1">
              <NewMap.MapAnimations
                centeredDriverIdSignal={centeredDriverIdSignal}
                routeSignal={routeSignal}
                sortedWaypoints={sortedWaypoints}
                right={47}
                left={47}
                bottom={88}
              />
              <NewMap.MapControlGroup bottom={6} right={6}>
                <NewMap.MapZoomControl />
              </NewMap.MapControlGroup>
              <Show when={route() || routeLoading()}>
                <RideForm.RideInfo />
              </Show>
              <NewMap.MapTaxistations features={taxistationFeatures()} />
              <Key each={visibleDrivers()} by={(liveLocation) => liveLocation.driver_id}>
                {(liveLocation) => (
                  <NewMap.MapDriverMarker
                    driver_id={liveLocation().driver_id}
                    follow={liveLocation().driver_id === centeredDriverId()}
                  />
                )}
              </Key>
              <For each={sortedWaypoints()}>
                {(place, i) => (
                  <NewMap.MapPlaceMarker
                    lngLat={API.PlaceAdapter.getCoordinates(place)}
                    color={i() === 0 ? "#2b71b7" : i() === sortedWaypoints().length - 1 ? "#23bb4c" : undefined}
                  />
                )}
              </For>
              <Show when={route()}>
                {(route) => (
                  <>
                    <NewMap.MapLineString linestring={route().linestring} />
                    <Show when={route().return_route}>
                      {(return_route) => (
                        <NewMap.MapLineString linestring={return_route().linestring} style="return_route" />
                      )}
                    </Show>
                  </>
                )}
              </Show>
            </NewMap.MapComponent>
            <div class="d-flex flex-column gap-1 p-1 position-relative">
              <RideForm.DragAndDropPlaces />
              <div class="d-flex gap-2">
                <RideForm.PlaceAddButton maxPlaceCount={5} />
                <PassengerCountButton />
                <Datepicker
                  locale={currentLocale()}
                  clearButton
                  placeholder={ct.common.date()}
                  datepicker={true}
                  timepicker={false}
                  position="top left"
                  onSelect={(timestamp) =>
                    rideFormEmitter.emit("startDatePicker.value", timestamp ? timestamp.getMyDate() : undefined)
                  }
                />
                <Datepicker
                  locale={currentLocale()}
                  clearButton
                  placeholder={ct.common.time()}
                  datepicker={false}
                  timepicker={true}
                  position="top left"
                  onSelect={(timestamp) =>
                    rideFormEmitter.emit("startTimePicker.value", timestamp ? timestamp.getMyTime() : undefined)
                  }
                />
                <RideForm.ClearRideFormButton />
              </div>
              <PassengerCountSelectorOverlay min={1} max={10} />
              <SimplePriceOptionsOverlay min={-1} max={1} step={0.05} />
            </div>
          </div>

          <NewPlaceSearch.PlaceSearchOverlay />
        </RideForm.RideFormProvider>

        <div style={{ width: "150px" }} class="p-2 h-100 overflow-y-auto overflow-x-hidden bg-white flex-shrink-0">
          <TransportcompanySelectorC />
          <hr />
          <Show when={currentRoles().some((role) => ["taxistations", "owner"].includes(role))}>
            <TaxistationsC />
            <hr />
          </Show>
          <ClientDriverlist
            liveLocations={onlineDrivers()}
            onDriverClick={(driver_id) => {
              setCenteredDriverId(driver_id);
              driverMarkerInstances.forEach((marker) => marker.getPopup()?.remove());
              const driverMarkerInstance = driverMarkerInstances.get(driver_id);
              if (driverMarkerInstance) {
                driverMarkerInstance.getPopup()?.addTo(driverMarkerInstance._map);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DriversPage;
