import { ParentComponent } from "solid-js";
import { SolidMapMarker } from "solid-map";
type Props = {
  lngLat: number[];
  color?: string;
};

export const MapPlaceMarker: ParentComponent<Props> = (props) => {
  return (
    <SolidMapMarker lngLat={props.lngLat as [number, number]}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27.38"
        height="27.38"
        viewBox="0 0 27.38 27.38"
        fill={props.color ?? "#000"}
      >
        <circle cx="13.69" cy="13.69" r="13.69" style="opacity:.2" />
        <circle cx="13.69" cy="13.69" r="6.03" />
      </svg>
    </SolidMapMarker>
  );
};
